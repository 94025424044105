import React, { useState } from "react"
import { NodeProps } from "react-flow-renderer"
import classNames from "classnames"
import { styled } from "@stitches/react"
import { CustomNode } from "../CustomNode"
import RhombusShape from "../../../components/shapes/Rhombus"
import NodeSettings from "../NodeSettings"
import EditableText from "../../../components/EditableText"
import useDeleteNode from "../../../hooks/useDeleteNode"

export default function ConditionNode(props: NodeProps) {
  const { id, data, selected, dragging } = props
  const [message, setMessage] = useState(data?.label ?? "")
  const [editing, setEditing] = useState(false)
  const onDelete = useDeleteNode(id)
  const onChange = (value?: string) => setMessage(value)

  return (
    <CustomNode.Container dynamicHandles id={id} dragging={dragging}>
      <Container
        className={classNames("react-flow__node-shape nopan", { selected, nodrag: editing })}>
        <RhombusShape
          fillColor={"#E1D5E7"}
          strokeWidth={selected ? 1.5 : 1}
          strokeDasharray={editing ? "3" : undefined}
        />
        <AbsoluteContainer>
          <CustomNode.Label>
            <EditableText value={message} onChange={onChange} onEditingChange={setEditing} />
          </CustomNode.Label>
        </AbsoluteContainer>
      </Container>
      <NodeSettings open={props.selected && !props.dragging} onDelete={onDelete} />
    </CustomNode.Container>
  )
}

const Container = styled("div", {
  alignItems: "center",
  justifyContent: "center"
})

const AbsoluteContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: "100%",
  height: "100%"
})
