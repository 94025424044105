import React, { useCallback } from "react"
import { EdgeProps, getBezierPath, useStore } from "react-flow-renderer"
import { getFloatingEdgeParams } from "./getFloatingEdgeParams"
import { NodeTypes } from "../../nodes/NodeTypes"

// TODO: this should be editable by the user. they should have
//       an option to select if they want the source handle or
//       the target handle to be floating or not
const floatingNodeTypes = [NodeTypes.BotMessage, NodeTypes.Condition, NodeTypes.FlowEnd] as any[]

export default function FloatingEdge(props: EdgeProps) {
  const { source, sourceX, sourceY, sourcePosition, target, targetX, targetY, targetPosition } =
    props
  const [sourceNode, targetNode] = useStore(
    useCallback(
      store => [store.nodeInternals.get(source), store.nodeInternals.get(target)],
      [source, target]
    )
  )

  if (!sourceNode || !targetNode) return null

  let sourceParams = { sourceX, sourceY, sourcePosition }
  let targetParams = { targetX, targetY, targetPosition }

  const sourceIsFloating = floatingNodeTypes.includes(sourceNode.type)
  const targetIsFloating = floatingNodeTypes.includes(targetNode.type)
  if (sourceIsFloating || targetIsFloating) {
    const { sx, sy, tx, ty, sourcePos, targetPos } = getFloatingEdgeParams(sourceNode, targetNode)
    if (sourceIsFloating) sourceParams = { sourceX: sx, sourceY: sy, sourcePosition: sourcePos }
    if (targetIsFloating) targetParams = { targetX: tx, targetY: ty, targetPosition: targetPos }
  }

  const d = getBezierPath({ ...sourceParams, ...targetParams })

  return (
    <path
      id={props.id}
      className="react-flow__edge-path"
      d={d}
      strokeWidth={5}
      markerEnd={props.markerEnd}
      style={props.style}
    />
  )
}
