import React, { PropsWithChildren } from "react"
import { DropdownMenu } from "../../components/DropdownMenu/DropdownMenu"
import { NodeTypes } from "../nodes/NodeTypes"
import { useFlowStore } from "../../contexts/rootStoreContext"

export default function CreateToolDropdownMenu(props: PropsWithChildren) {
  const flowStore = useFlowStore()

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{props.children}</DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <DropdownMenu.Label>Generic</DropdownMenu.Label>
        <DropdownMenu.Item
          disabled={flowStore.nodes.some(n => n.type === NodeTypes.FlowStart)}
          label="Flow Start"
          onClick={() => flowStore.addNode(NodeTypes.FlowStart)}
        />
        <DropdownMenu.Item
          label="Bot Message"
          onClick={() =>
            flowStore.addNode(NodeTypes.BotMessage, { label: "Double click to edit this message" })
          }
        />
        <DropdownMenu.Item
          label="Condition"
          onClick={() => flowStore.addNode(NodeTypes.Condition, { label: "Condition" })}
        />
        <DropdownMenu.Item label="Flow End" onClick={() => flowStore.addNode(NodeTypes.FlowEnd)} />
        <DropdownMenu.Item
          disabled
          label="Next Flow"
          onClick={() => flowStore.addNode(NodeTypes.NextFlow)}
        />
        <DropdownMenu.Separator />
        <DropdownMenu.Label>Inputs</DropdownMenu.Label>
        <DropdownMenu.Item
          disabled
          label="Free Text"
          onClick={() => flowStore.addNode(NodeTypes.FreeText)}
        />
        <DropdownMenu.Item
          disabled
          label="Single Choice"
          onClick={() => flowStore.addNode(NodeTypes.SingleChoice)}
        />
        <DropdownMenu.Item
          disabled
          label="Multiple Choice"
          onClick={() => flowStore.addNode(NodeTypes.MultipleChoice)}
        />
        <DropdownMenu.Item
          disabled
          label="Slider"
          onClick={() => flowStore.addNode(NodeTypes.Slider)}
        />
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
