import { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { toJS } from "mobx"
import ReactFlow, { ReactFlowInstance } from "react-flow-renderer"
import { useFlowStore } from "../../contexts/rootStoreContext"
import { useWindowSize } from "../../hooks/useWindowSize"
import { Column } from "../../components/Layout"
import FlowHeader from "../../components/FlowHeader"
import MainToolBar from "../../views/menus/MainToolBar"
import MainContextMenu from "../../views/menus/MainContextMenu"
import { NodeTypes } from "../../views/nodes/NodeTypes"
import { EdgeTypes } from "../../views/edges/EdgeTypes"
import { connectionLineStyle, defaultEdgeOptions, snapGrid } from "../../config"
import {
  EdgeTypes as RFR_EdgeTypes,
  NodeTypes as RFR_NodeTypes
} from "react-flow-renderer/dist/esm/types/general"
import FlowStartNode from "../../views/nodes/generic/FlowStartNode"
import FlowEndNode from "../../views/nodes/generic/FlowEndNode"
import BotMessageNode from "../../views/nodes/generic/BotMessageNode"
import ConditionNode from "../../views/nodes/generic/ConditionNode"
import CustomEdge from "../../views/edges/CustomEdge"
import FloatingEdge from "../../views/edges/FloatingEdge/FloatingEdge"
import { ReactFlowInstanceProvider } from "../../hooks/useReactFlowInstance"

const nodeTypes: RFR_NodeTypes = {
  [NodeTypes.FlowStart]: FlowStartNode,
  [NodeTypes.FlowEnd]: FlowEndNode,
  [NodeTypes.BotMessage]: BotMessageNode,
  [NodeTypes.Condition]: ConditionNode
}

const edgeTypes: RFR_EdgeTypes = {
  [EdgeTypes.Custom]: CustomEdge,
  [EdgeTypes.Floating]: FloatingEdge
}

function FlowEditorScreen() {
  const [rfInstance, setRfInstance] = useState<ReactFlowInstance>()
  const flowStore = useFlowStore()
  const size = useWindowSize()

  useEffect(() => {
    flowStore.setFlowInstance(rfInstance)
  }, [flowStore, rfInstance])

  return (
    <ReactFlowInstanceProvider instance={rfInstance}>
      <Column style={{ display: "flex", ...size }}>
        <FlowHeader
          title={"[INSIGHT] SelfReferral Dialogue"}
          description={
            "A simple dialogue to collect a set of answers required for the self referral record"
          }
        />
        <Column css={{ margin: 8 }}>
          <MainToolBar />
        </Column>
        <MainContextMenu>
          <ReactFlow
            fitView
            snapToGrid
            panOnScroll
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodes={toJS(flowStore.nodes)}
            edges={toJS(flowStore.edges)}
            defaultEdgeOptions={defaultEdgeOptions}
            connectionLineStyle={connectionLineStyle}
            snapGrid={snapGrid}
            style={rfStyle}
            onInit={setRfInstance}
            onNodesChange={flowStore.applyNodeChanges}
            onEdgesChange={flowStore.applyEdgeChanges}
            onNodesDelete={flowStore.deleteNodes}
            onEdgesDelete={flowStore.deleteEdges}
            onConnect={flowStore.addEdge}
          />
        </MainContextMenu>
      </Column>
    </ReactFlowInstanceProvider>
  )
}

export default observer(FlowEditorScreen)

const rfStyle = { zIndex: 0 }
