import React, { ForwardedRef, forwardRef, PropsWithChildren, useMemo } from "react"
import { CSSProperties, styled } from "@stitches/react"
import { Handle, useStore } from "react-flow-renderer"
import { HandleStyleFn, getDynamicHandles } from "../../../utils/getDynamicHandles"
import { Row } from "../../components/Layout"

interface IProps {
  id: string
  dragging?: boolean
  dynamicHandles?: boolean
  sourceHandleStyle?: CSSProperties | HandleStyleFn
  targetHandleStyle?: CSSProperties | HandleStyleFn
}

const NodeContainer = forwardRef(
  (props: PropsWithChildren<IProps>, ref: ForwardedRef<HTMLDivElement>) => {
    const { id, dragging, dynamicHandles, sourceHandleStyle, targetHandleStyle } = props
    const store = useStore()

    const h = useMemo(() => {
      if (!dynamicHandles) return null
      const handles = getDynamicHandles(id, store, sourceHandleStyle, targetHandleStyle)
      return handles.map(handle => <Handle key={handle.id} {...handle} />)
    }, [id, dynamicHandles, sourceHandleStyle, store, targetHandleStyle])

    return (
      <ContainerRow ref={ref} dragging={dragging}>
        {h}
        {props.children}
      </ContainerRow>
    )
  }
)

const ContainerRow = styled(Row, {
  variants: {
    dragging: {
      true: { opacity: 0.5 },
      false: { opacity: 1 }
    }
  }
})

const Label = styled("span", {
  fontSize: 12,
  textAlign: "center"
})

export const CustomNode = {
  Container: NodeContainer,
  Label: Label
}
