import { PlusIcon } from "@radix-ui/react-icons"
import { Toolbar } from "../../components/Toolbar/Toolbar"
import CreateToolDropdownMenu from "./CreateToolDropdownMenu"

export default function MainToolBar() {
  return (
    <Toolbar.Root aria-label="Main toolbar">
      <CreateToolDropdownMenu>
        <Toolbar.DropdownButton aria-label="Create Node">
          <PlusIcon />
        </Toolbar.DropdownButton>
      </CreateToolDropdownMenu>

      <Toolbar.Separator />
      <Toolbar.Link href="#" target="_blank" css={{ marginRight: 10 }}>
        Edited 2 hours ago
      </Toolbar.Link>
    </Toolbar.Root>
  )
}
