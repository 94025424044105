import { createContext, PropsWithChildren, useContext } from "react"
import { RootStore } from "../stores/RootStore"
import { FlowStore } from "../stores/FlowStore"

const ctx = createContext<RootStore | null>(null)

interface IRootStoreProviderProps {
  rootStore: RootStore
}

export function RootStoreProvider(props: PropsWithChildren<IRootStoreProviderProps>) {
  const { rootStore, ...restProps } = props
  return <ctx.Provider value={props.rootStore} {...restProps} />
}

export function useRootStore(): RootStore {
  const root = useContext(ctx)
  if (!root) throw new Error("Can't call useRootStore outside a provider")
  return root
}

export function useFlowStore(): FlowStore {
  const rootStore = useRootStore()
  return rootStore.flowStore
}
