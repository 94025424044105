import { memo } from "react"
import { gray } from "@radix-ui/colors"

interface IProps {
  size: number
  strokeWidth: number
  strokeColor: string
  fillColor: string
}

function EndShape(props: IProps): JSX.Element {
  const { size, strokeWidth, strokeColor, fillColor } = props
  return (
    <svg width={size} height={size} style={{ display: "block", overflow: "visible" }}>
      <circle
        className="circle"
        cx={size / 2}
        cy={size / 2}
        r={size / 2}
        fill={"transparent"}
        strokeWidth={strokeWidth}
        stroke={strokeColor}
      />
      <circle
        className="circle"
        cx={size / 2}
        cy={size / 2}
        r={size / 2 - 4}
        fill={fillColor}
        strokeWidth={strokeWidth}
        stroke={strokeColor}
      />
    </svg>
  )
}

EndShape.defaultProps = {
  size: 100,
  strokeWidth: 1,
  fillColor: gray.gray1,
  strokeColor: "black"
}

export default memo(EndShape)
