export enum NodeTypes {
  FlowStart = "flowStart",
  FlowEnd = "flowEnd",
  BotMessage = "botMessage",
  Condition = "condition",
  NextFlow = "nextFlow",
  FreeText = "freeText",
  SingleChoice = "singleChoice",
  MultipleChoice = "multipleChoice",
  Slider = "slider"
}
