import { FlowStore } from "./FlowStore"

export class RootStore {
  static __instance: RootStore
  static getInstance(): RootStore {
    if (!this.__instance) this.__instance = new RootStore()
    return this.__instance
  }

  flowStore: FlowStore

  constructor() {
    this.flowStore = new FlowStore()
  }
}

export default RootStore.getInstance()

interface IWindowWithRootStore extends Window {
  RootStore: RootStore
}

declare let window: IWindowWithRootStore
if (!window.RootStore) window.RootStore = RootStore.getInstance()
