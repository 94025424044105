import React from "react"
import { EdgeProps, Position } from "react-flow-renderer"

interface IPoint {
  x: number
  y: number
}
const point = (x: number, y: number): IPoint => ({ x, y })
const _2d = (p: IPoint): string => `${p.x},${p.y}`

const MIN_EDGE_SEGMENT_LENGTH = 20

function getBufferPoint(s: IPoint, t: IPoint, position: Position): IPoint {
  const isCrossedX = { [Position.Left]: t.x > s.x, [Position.Right]: t.x < s.x }[position]
  const isCrossedY = { [Position.Top]: t.y > s.y, [Position.Bottom]: t.y < s.y }[position]

  const deltaX = Math.abs(t.x - s.x) * 0.3
  const deltaY = Math.abs(t.y - s.y) * 0.3

  const dX = isCrossedX ? MIN_EDGE_SEGMENT_LENGTH : Math.max(MIN_EDGE_SEGMENT_LENGTH, deltaX)
  const dY = isCrossedY ? MIN_EDGE_SEGMENT_LENGTH : Math.max(MIN_EDGE_SEGMENT_LENGTH, deltaY)

  return {
    [Position.Left]: { ...s, x: s.x - dX },
    [Position.Right]: { ...s, x: s.x + dX },
    [Position.Top]: { ...s, y: s.y - dY },
    [Position.Bottom]: { ...s, y: s.y + dY }
  }[position]
}

function getPath(source: IPoint, target: IPoint, sPosition: Position, tPosition: Position): string {
  const p0 = _2d(source)
  const p1 = _2d(getBufferPoint(source, target, sPosition))
  const p2 = _2d(getBufferPoint(target, source, tPosition))
  const p3 = _2d(target)

  return `M ${p0} L${p1} ${p2} ${p3}`
}

export default function CustomEdge(props: EdgeProps) {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    markerEnd
  } = props

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={getPath(
          point(sourceX, sourceY),
          point(targetX, targetY),
          sourcePosition,
          targetPosition
        )}
        markerEnd={markerEnd}
      />
      <text>
        <textPath
          href={`#${id}`}
          style={{ fontSize: "12px" }}
          startOffset="50%"
          textAnchor="middle">
          {data?.label}
        </textPath>
      </text>
    </>
  )
}
