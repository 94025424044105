import { action, makeAutoObservable, observable } from "mobx"
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  EdgeChange,
  getRectOfNodes,
  NodeChange,
  Position,
  ReactFlowInstance
} from "react-flow-renderer"
import { Convui } from "../../models/Convui"
import { NodeTypes } from "../views/nodes/NodeTypes"
import Vector2 from "../../models/Vector2"

export class FlowStore {
  newNodeID: number
  flowInstance?: ReactFlowInstance
  @observable title: string
  @observable description?: string
  @observable nodes: Convui.Node[]
  @observable edges: Convui.Edge[]

  constructor() {
    this.newNodeID = 0
    this.title = "Untitled Flow"
    this.nodes = []
    this.edges = []
    makeAutoObservable(this)
  }

  setFlowInstance(instance?: ReactFlowInstance): void {
    this.flowInstance = instance
  }

  /** Actions */

  @action
  setTitle(title?: string): void {
    this.title = title ?? "Untitled Flow"
  }

  @action
  setDescription(description?: string): void {
    this.description = description
  }

  @action
  setNodes(nodes: Convui.Node[]): void {
    this.nodes = nodes
  }

  @action
  setEdges(edges: Convui.Edge[]): void {
    this.edges = edges
  }

  @action
  applyNodeChanges = (changes: NodeChange[]): void => {
    this.setNodes(applyNodeChanges(changes, this.nodes))
  }

  @action
  applyEdgeChanges = (changes: EdgeChange[]): void => {
    this.setEdges(applyEdgeChanges(changes, this.edges))
  }

  @action
  addEdge = (connection: Connection): void => {
    this.setEdges(addEdge(connection, this.edges))
  }

  @action
  addNode(type: NodeTypes, data?: { label?: string; position?: Vector2 }): Convui.Node {
    const { label, position = this.getNewNodePosition() } = data ?? {}
    const prependStart = !this.nodes.length && type !== NodeTypes.FlowStart
    const startNode = prependStart
      ? this.addStartingNode({ x: position.x, y: position.y - 100 })
      : undefined

    this.newNodeID += 1
    const id = `${this.newNodeID}`
    const newNode: Convui.Node = { id, type, data: { label }, position }
    this.setNodes([...this.nodes, newNode])

    if (startNode) {
      this.addEdge({
        source: startNode.id,
        target: id,
        sourceHandle: null,
        targetHandle: `t-${Position.Top}`
      })
    }

    // TODO: This should be configurable from a setting dropdown
    //       where you have options like "Auto Focus New Items",
    //       "Show Minimap", and so on
    this.centerLastNode()

    return newNode
  }

  @action
  addStartingNode(position): Convui.Node {
    this.newNodeID += 1
    const id = `${this.newNodeID}`
    const startNode = { id, type: NodeTypes.FlowStart, data: {}, position }
    this.setNodes([...this.nodes, startNode])
    return startNode
  }

  @action
  deleteNodes = (nodes: Convui.Node[]): void => {
    const ids = nodes.map(n => n.id)
    this.setNodes(this.nodes.filter(n => !ids.includes(n.id)))
  }

  @action
  deleteEdges = (edges: Convui.Edge[]): void => {
    const ids = edges.map(n => n.id)
    this.setEdges(this.edges.filter(n => !ids.includes(n.id)))
  }

  /** Generic Handlers */

  getNewNodePosition(): Vector2 {
    if (!this.nodes.length) return { x: 0, y: 0 }
    const rect = getRectOfNodes(this.nodes)
    return { x: rect.x + rect.width / 2, y: rect.y + rect.height + 60 }
  }

  centerLastNode(): void {
    const lastNode = this.nodes[this.nodes.length - 1]
    this.flowInstance?.setCenter(lastNode.position.x, lastNode.position.y, { duration: 400 })
  }
}
