import { memo } from "react"
import { gray } from "@radix-ui/colors"

interface IProps {
  size: number
  strokeWidth: number
  strokeColor: string
  fillColor: string
  strokeDasharray?: string
}

function RhombusShape(props: IProps): JSX.Element {
  const { size, strokeWidth, strokeColor, fillColor, strokeDasharray } = props
  const d = getVectorPath(size)
  return (
    <svg width={size} height={size} style={{ display: "block", overflow: "visible" }}>
      <path
        d={d}
        fill={fillColor}
        strokeWidth={strokeWidth}
        stroke={strokeColor}
        strokeDasharray={strokeDasharray}></path>
    </svg>
  )
}

RhombusShape.defaultProps = {
  size: 100,
  strokeWidth: 1,
  fillColor: gray.gray1,
  strokeColor: "black"
}

const getVectorPath = (n: number) => `M0,${n / 2} L${n / 2},0 L${n},${n / 2} L${n / 2},${n} z`

export default memo(RhombusShape)
