import {
  DropdownMenuCheckboxItemProps,
  DropdownMenuItemProps,
  DropdownMenuRadioItemProps,
  DropdownMenuSubTriggerProps
} from "@radix-ui/react-dropdown-menu"
import { styled } from "@stitches/react"
import { mauve, violet } from "@radix-ui/colors"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { MenuRow } from "../MenuRow"
import { CheckIcon, ChevronRightIcon, DotFilledIcon } from "@radix-ui/react-icons"

interface IItemProps extends DropdownMenuItemProps, React.RefAttributes<HTMLDivElement> {
  label: string
  left?: string | JSX.Element
  right?: string | JSX.Element
}

export function DropdownMenuItem(props: IItemProps): JSX.Element {
  const { label, left, right, ...restProps } = props
  return (
    <Item {...restProps}>
      <MenuRow {...{ label, left, right }} />
    </Item>
  )
}

interface ICheckboxProps
  extends DropdownMenuCheckboxItemProps,
    React.RefAttributes<HTMLDivElement> {
  label: string
  right?: string | JSX.Element
}

export function DropdownMenuCheckbox(props: ICheckboxProps): JSX.Element {
  const { label, right, ...restProps } = props
  return (
    <CheckboxItem {...restProps}>
      <Indicator>
        <CheckIcon />
      </Indicator>
      <MenuRow label={label} right={right} />
    </CheckboxItem>
  )
}

interface IRadioProps extends DropdownMenuRadioItemProps, React.RefAttributes<HTMLDivElement> {
  label: string
  right?: string | JSX.Element
}

export function DropdownMenuRadio(props: IRadioProps): JSX.Element {
  const { label, right, ...restProps } = props
  return (
    <RadioItem {...restProps}>
      <Indicator>
        <DotFilledIcon />
      </Indicator>
      <MenuRow label={label} right={right} />
    </RadioItem>
  )
}

interface ISubTriggerProps
  extends DropdownMenuSubTriggerProps,
    React.RefAttributes<HTMLDivElement> {
  label: string
}

export function DropdownMenuSubTrigger(props: ISubTriggerProps): JSX.Element {
  const { label, ...restProps } = props
  return (
    <SubTrigger {...restProps}>
      <MenuRow label={label} right={<ChevronRightIcon />} />
    </SubTrigger>
  )
}

const itemStyles = {
  all: "unset",
  fontSize: 13,
  lineHeight: 1,
  color: violet.violet11,
  borderRadius: 3,
  display: "flex",
  alignItems: "center",
  height: 25,
  padding: "0 5px",
  position: "relative",
  paddingLeft: 25,
  userSelect: "none",

  "&[data-disabled]": {
    color: mauve.mauve8,
    pointerEvents: "none"
  },

  "&[data-highlighted]": {
    backgroundColor: violet.violet9,
    color: violet.violet1
  }
}

const Item = styled(DropdownMenuPrimitive.Item, { ...itemStyles })
const CheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem, { ...itemStyles })
const RadioItem = styled(DropdownMenuPrimitive.RadioItem, { ...itemStyles })

const SubTrigger = styled(DropdownMenuPrimitive.SubTrigger, {
  ...itemStyles,
  '&[data-state="open"]': {
    backgroundColor: violet.violet4,
    color: violet.violet11
  }
})

const Indicator = styled(DropdownMenuPrimitive.ItemIndicator, {
  position: "absolute",
  left: 0,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: mauve.mauve12,
  "[data-highlighted] > &": { color: "white" },
  "[data-disabled] &": { color: mauve.mauve8 }
})
