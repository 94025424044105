import { useState } from "react"
import { NodeProps, Position } from "react-flow-renderer"
import classNames from "classnames"
import { CustomNode } from "../CustomNode"
import NodeSettings from "../NodeSettings"
import EditableText from "../../../components/EditableText"
import { styled } from "@stitches/react"
import useDeleteNode from "../../../hooks/useDeleteNode"

const sourceStyle = ({ position, style }) => ({ ...style, ...styleMap[position] })

export default function BotMessageNode(props: NodeProps) {
  const { id, data, selected, dragging } = props
  const [message, setMessage] = useState(data?.label ?? "")
  const [editing, setEditing] = useState(false)
  const onDelete = useDeleteNode(id)
  const onChange = (value?: string) => setMessage(value)

  return (
    <CustomNode.Container
      id={id}
      dynamicHandles
      dragging={dragging}
      sourceHandleStyle={sourceStyle}
      targetHandleStyle={sourceStyle}>
      <Container
        editing={editing}
        className={classNames("react-flow__node-default", { selected, nodrag: editing })}>
        <CustomNode.Label>
          <EditableText value={message} onChange={onChange} onEditingChange={setEditing} />
        </CustomNode.Label>
      </Container>
      <NodeSettings open={props.selected && !props.dragging} onDelete={onDelete} />
    </CustomNode.Container>
  )
}

const horizontalHandleStyle = {
  width: 20,
  height: 5,
  borderRadius: 0
}

const verticalHandleStyle = {
  width: 5,
  height: 20,
  borderRadius: 0
}

const styleMap = {
  [Position.Left]: verticalHandleStyle,
  [Position.Right]: verticalHandleStyle,
  [Position.Top]: horizontalHandleStyle,
  [Position.Bottom]: horizontalHandleStyle
}

const Container = styled("div", {
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#FFF2CC !important",
  variants: {
    editing: { true: { borderStyle: "dashed", cursor: "text" } }
  }
})
