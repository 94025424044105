import { styled } from "@stitches/react"
import { Box } from "./Layout"

interface IProps {
  title?: string
  description?: string
}

export default function FlowHeader(props: IProps) {
  return (
    <Box css={{ padding: 16 }}>
      <Title>{props.title ?? "️️Untitled ✏️"}</Title>
      <Description>{props.description}</Description>
    </Box>
  )
}

const Title = styled("h1", {
  fontSize: 21,
  fontWeight: 500,
  marginBottom: 8
})

const Description = styled("span", {
  fontSize: 16,
  lineHeight: "20px",
  color: "grey"
})
