import { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react"
import { ContextMenu } from "../../components/ContextMenu/ContextMenu"
import { observer } from "mobx-react"
import { useFlowStore } from "../../contexts/rootStoreContext"
import { NodeTypes } from "../nodes/NodeTypes"
import Vector2 from "../../../models/Vector2"
import { useReactFlowInstance } from "../../hooks/useReactFlowInstance"

const { Root, Trigger, Content, Label, Item, Sub, SubTrigger, SubContent, Separator } = ContextMenu

function MainContextMenu(props: PropsWithChildren) {
  const [clickCoords, setClickCoords] = useState<Vector2 | undefined>()
  const ref = useRef<HTMLDivElement>(null)
  const flowStore = useFlowStore()
  const rfInstance = useReactFlowInstance()

  const onOpenChange = useCallback((open: boolean) => !open && setClickCoords(undefined), [])

  useEffect(() => {
    const trigger = ref.current
    const handleClick = (e: MouseEvent) => {
      e.preventDefault()
      const bounds = ref.current!.getBoundingClientRect()
      const coords = rfInstance?.project({ x: e.clientX - bounds.left, y: e.clientY - bounds.top })
      setClickCoords(coords)
    }
    trigger?.addEventListener("mousedown", handleClick)
    return () => {
      trigger?.removeEventListener("mousedown", handleClick)
    }
  }, [rfInstance])

  return (
    <Root onOpenChange={onOpenChange}>
      <Trigger asChild ref={ref}>
        {props.children}
      </Trigger>
      <Content sideOffset={5} align="end">
        <Label>Tools</Label>
        <Item label="Save" right="⌘+S" />

        <Sub>
          <SubTrigger label="Create" />
          <SubContent sideOffset={2} alignOffset={-5}>
            <Label>Generic</Label>
            <Item
              disabled={flowStore.nodes.some(n => n.type === NodeTypes.FlowStart)}
              label="Flow Start"
              onClick={() => flowStore.addNode(NodeTypes.FlowStart, { position: clickCoords })}
            />
            <Item
              label="Bot Message"
              onClick={() =>
                flowStore.addNode(NodeTypes.BotMessage, {
                  label: "Double click to edit this message",
                  position: clickCoords
                })
              }
            />
            <Item
              label="Condition"
              onClick={() =>
                flowStore.addNode(NodeTypes.Condition, {
                  label: "Condition",
                  position: clickCoords
                })
              }
            />
            <Item
              label="Flow End"
              onClick={() => flowStore.addNode(NodeTypes.FlowEnd, { position: clickCoords })}
            />
            <Item
              disabled
              label="Next Flow"
              onClick={() => flowStore.addNode(NodeTypes.NextFlow, { position: clickCoords })}
            />
            <Separator />
            <Label>Inputs</Label>
            <Item
              disabled
              label="Free Text"
              onClick={() => flowStore.addNode(NodeTypes.FreeText, { position: clickCoords })}
            />
            <Item
              disabled
              label="Single Choice"
              onClick={() => flowStore.addNode(NodeTypes.SingleChoice, { position: clickCoords })}
            />
            <Item
              disabled
              label="Multiple Choice"
              onClick={() => flowStore.addNode(NodeTypes.MultipleChoice, { position: clickCoords })}
            />
            <Item
              disabled
              label="Slider"
              onClick={() => flowStore.addNode(NodeTypes.Slider, { position: clickCoords })}
            />
          </SubContent>
        </Sub>
        <Separator />

        <Label>View</Label>
        <Item label="Fit view" right="⌘+Enter" />
        <Item label="Zoom In" right="⌘+[" />
        <Item label="Zoom Out" right="⌘+]" />
      </Content>
    </Root>
  )
}

export default observer(MainContextMenu)
