import { RouterProvider } from "react-router-dom"
import { RootStoreProvider } from "./contexts/rootStoreContext"
import { router } from "./screens/router"
import rootStore from "./stores/RootStore"

export default function App(): JSX.Element {
  return (
    <RootStoreProvider rootStore={rootStore}>
      <RouterProvider router={router} />
    </RootStoreProvider>
  )
}
